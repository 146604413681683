import React from "react"
import { connect, useSelector } from "react-redux"
import { ForexQuery } from "../../prismic/staticQueries"

import Layout from "../../components/layout"
import ContentWithTable from "../../components/content-basic/content-with-table"

const Forex = () => {
  const language = useSelector(state => state.language)
  const forexData = ForexQuery(language)
  return (
    <Layout>
      <ContentWithTable {...forexData} />
    </Layout>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(Forex)
